export const items = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    group: 'dashboard',
    to: '/admin/dashboard',
  },
  {
    title: 'Administrators',
    icon: 'mdi-account-group',
    group: 'dashboard',
    to: '/admin/users/admins',
  },
  {
    title: 'SMCs',
    icon: 'mdi-account-group',
    group: 'dashboard',
    to: '/admin/users/smcs',
  },
  {
    title: 'Registrants',
    icon: 'mdi-account-group',
    group: 'dashboard',
    to: '/admin/users/registrants',
  },
  {
    title: 'States',
    icon: 'mdi-map-marker',
    group: 'dashboard',
    to: '/admin/states',
  },
  {
    title: 'Clubs',
    icon: 'mdi-account-multiple',
    group: 'dashboard',
    to: '/admin/clubs',
  },
  {
    title: 'Club Products',
    icon: 'mdi-folder',
    group: 'dashboard',
    to: '/admin/club-products',
  },
  {
    title: 'Products',
    icon: 'mdi-folder',
    group: 'dashboard',
    to: '/admin/products',
  },
  {
    title: 'Packages',
    icon: 'mdi-package',
    group: 'dashboard',
    to: '/admin/packages',
  },
  {
    title: 'Orders',
    icon: 'mdi-order-bool-ascending',
    group: 'dashboard',
    to: '/admin/orders',
  },
  {
    title: 'Prize Destinations',
    icon: 'mdi-seal-variant',
    group: 'dashboard',
    to: '/admin/prizedestination',
  },
  {
    title: 'Reports',
    icon: 'mdi-chart-bar',
    group: 'dashboard',
    to: '/admin/reports',
  },
]
